import React from 'react';

import Icon from './Icon';

export default function VideoIcon({
  className = '',
  text = 'Video',
}: { className?: string; text?: string; }): JSX.Element {
  return (
    <Icon text={text}>
      <svg
        className={className}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M4 8C4 6.89543 4.89543 6 6 6H12C13.1046 6 14 6.89543 14 8V16C14 17.1046 13.1046 18 12 18H6C4.89543 18 4 17.1046 4 16V8Z" />
        <path d="M16.5528 9.10557C16.214 9.27497 16 9.62123 16 10V14C16 14.3788 16.214 14.725 16.5528 14.8944L18.5528 15.8944C18.8628 16.0494 19.2309 16.0329 19.5257 15.8507C19.8205 15.6684 20 15.3466 20 15V9C20 8.65342 19.8205 8.33156 19.5257 8.14935C19.2309 7.96714 18.8628 7.95058 18.5528 8.10557L16.5528 9.10557Z" />
      </svg>
    </Icon>
  );
}
